
<template>
    <div>
        <!-- begin::Districts Layout -->
        <!-- begin::Header -->
        <div class="hidden lg:block">
            <Header />
        </div>
        <!-- end::Header -->

        <!-- begin::MENU mobile -->
        <div class="lg:hidden">
            <MobileHeader />
        </div>
        <!-- end::MENU mobile -->

        <!-- begin::Page -->
        <router-view />
        <!-- end::Page -->
        <!-- end::Districts Layout -->
    </div>
</template>

<script>
    import Header from '@/layout/header/Header'
    import MobileHeader from '@/layout/header/mobile/MobileHeader'
    export default {
        components: {
            Header,
            MobileHeader
        }
    }
</script>