<template>
    <div class="w-screen glass-effect glass-fallback glass-effect-white flex items-center px-4 py-2 fixed z-50">
        <div class="w-1/12 flex">
        <!-- begin::Search -->
        <SearchBar color="black" class="ml-4"/>
        <!-- <Search class="ml-4 -mt-16" srcImage="/svg/loupe.svg" srcWidth="1.4rem" srcHeight="1.4rem"/> -->
        <!-- end::Search -->
         <!-- begin::locales-switcher -->
        <LocaleSwitcher :toCenter="true"/>
        <!-- end::locales-switcher -->
        </div>
        <!-- begin::Main-menu -->
        <MainMenu class=" w-10/12 items-center justify-center" />
        <!-- end::Main-menu -->
        <!-- begin::Menu-icon -->
        <HamburgerMenu class=" w-1/12 flex justify-end" color="black" toCenter="true"/>
        <!-- end::Main-icon -->
    </div>
</template>
<script>
    // import Search from "@/components/search/Search";
    import MainMenu from "@/layout/header/MainMenu";
    import HamburgerMenu from "@/components/core/HamburgerMenu";
    import LocaleSwitcher from '@/components/core/LocaleSwitcher';
    import SearchBar from '@/components/core/SearchBar';

    export default {
        name: "Header",
        components: {MainMenu, HamburgerMenu, LocaleSwitcher, SearchBar}
    }
</script>